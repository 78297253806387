import React from 'react';
import logo from '../img/the_tour.png';
import very_soon from '../img/very_soon.jpg';
import './AVerySoon.css';
import {Grid} from '@mui/material';

function App() {
  return (
    <article className="App-article">
      <Grid container >
        <Grid item xs={4}></Grid>
        <Grid item xs={4} container alignItems="center" direction="column" justifyContent="center">
          <img src={logo} className="App-logo" alt="logo"/>
        </Grid>
        <Grid item xs={12} container alignItems="center" direction="column" justifyContent="center">
          <img src={very_soon} className="App-very-soon" alt="very_soon" />
        </Grid>
      </Grid>
    </article>
  );
}

export default App;
